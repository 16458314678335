























































































import '@/components/Vue2Leaflet';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import date from 'quasar/src/utils/date.js';;
import config from '../config';
import resize from 'vue-resize-directive';
import * as L from 'leaflet';

@Component({
  directives: {
    resize,
  },
})
export default class ManageBuildingDeploy extends Vue {
  @Prop() public id!: number;
  @Prop() public building!: any;
  public $store: any;
  public $q: any;
  public areas: any = [];
  public levelsWithImages: any = [];
  private mapCRS: any = L.CRS.Simple;
  private currentLevelId: any = null;

  public created() {
    this.updateAreas();
    this.updateLevelsWithImages();
  }

  get currentLevelImageUrl() {
    if (this.levelsWithImages.length === 0) {
      return null;
    }
    return `${config.api_url}/organisation/building/${this.building.id}/level/${this.currentLevel.id}/image`;
  }

  get currentLevelImageBounds() {
    if (this.levelsWithImages.length === 0) {
      return [[0, 0], [10, 10]];
    }
    return [
      [0, 0],
      [
        this.currentLevel.image.size[1],
        this.currentLevel.image.size[0],
      ],
    ];
  }

  get currentLevelGeojson() {
    if (!this.currentLevel.geojson) {
      return null;
    }
    const geojson: any = this.currentLevel.geojson;
    return {
      type: geojson.type,
      features: geojson.features.filter((feature: any) =>
        (
          feature.type === 'Feature' &&
          feature.geometry.type === 'Point' &&
          feature.properties.areaId !== undefined &&
          this.getAreaFromId(feature.properties.areaId).analyzer
        ),
      ).map((feature: any) => {
        return feature;
      }),
    };
  }

  private currentLevelPointToLayer(feature, latlng) {
    const area = this.getAreaFromId(feature.properties.areaId);
    const offline = this.isAnalyzerOffline(area.analyzer);
    let className = 'material-icons q-icon notranslate placeicon';
    let html = 'place';
    if (offline) {
      className += ' placeicon-offline';
      html = 'wrong_location';
    }

    const myIcon = L.divIcon({
      iconSize: [48, 48],
      iconAnchor: [24, 48],
      html,
      className,
    });
    const marker = L.marker(latlng, {icon: myIcon});
    marker.bindTooltip(
      `<b>${area.analyzer.serial}</b><br/>${area.name}`, {
      direction: 'bottom',
    });
    return marker;
  }

  @Watch('currentLevelId')
  private refreshLevelFit() {
    Vue.nextTick(() => {
      this.refreshLevelFitReal();
    });
  }

  private refreshLevelFitReal() {
    if (this.levelsWithImages.length === 0) {
      return;
    }
    const lmap: any = this.$refs.lmap;
    if (lmap === undefined) {
      return;
    }
    lmap.mapObject.fitBounds(
      this.currentLevelImageBounds, {
        animate: false,
      });
  }

  @Watch('building')
  private updateLevelsWithImages() {
    if (this.building === undefined || this.building.levels === undefined) {
      return [];
    }
    const levels: any[] = [];
    for (const level of this.building.levels) {
      if (level.image !== null) {
        levels.push(level);
        if (this.currentLevelId === null) {
          this.currentLevelId = level.id;
        }
      }
    }
    this.levelsWithImages = levels;
    this.refreshLevelFit();
  }

  @Watch('building')
  private updateAreas() {
    if (this.building === undefined || this.building.levels === undefined) {
      return [];
    }
    const areas: any[] = [];
    for (const level of this.building.levels) {
      for (const area of level.areas) {
        if (area.analyzer !== null) {
          areas.push(area);
        }
      }
    }
    this.areas = areas;
  }

  get currentLevel() {
    if (this.levelsWithImages.length === 0) {
      return;
    }
    for (const level of this.levelsWithImages) {
      if (level.id === this.currentLevelId) {
        return level;
      }
    }
  }

  private getLevelImageUrl(level: any) {
    return `${config.api_url}/organisation/building/${this.building.id}/level/${level.id}/image`;
  }

  private getAreaFromId(areaId: number) {
    for (const area of this.currentLevel.areas) {
      if (area.id === areaId) {
        return area;
      }
    }
  }

  private getAnalyzers(level: any) {
    const analyzers: any[] = [];
    for (const area of level.areas) {
      if (area.analyzer && area.analyzer.plan_x !== null && area.analyzer.plan_x !== undefined) {
        analyzers.push(area.analyzer);
      }
    }
    return analyzers;
  }

  private getAnalyzerStyle(analyzer: any) {
    let color: string|null = null;
    if (this.isAnalyzerOffline(analyzer)) {
      color = '#FF8989';
    }
    return {
      left: `${analyzer.plan_x}%`,
      top: `${analyzer.plan_y}%`,
      color,
    };
  }

  private getAnalyzerTimeDiff(analyzer: any) {
    if (analyzer === undefined) {
      return -1;
    }
    if (analyzer.last_updated_at === null) {
      return -1;
    }
    const d1 = new Date();
    const d2 = Date.parse(analyzer.last_updated_at);
    const diff = date.getDateDiff(d1, d2, 'minutes');
    return diff;
  }

  private isAnalyzerOffline(analyzer: any) {
    const timediff = this.getAnalyzerTimeDiff(analyzer);
    if (timediff === -1) {
      return true;
    }
    return timediff >= 60;
  }

  private handleResize(element: any) {
    Vue.nextTick(() => {
      this.delayedResize(element);
    });
    setTimeout(() => {
      this.delayedResize(element);
    }, 100);
  }

  private delayedResize(element: any) {
    const img = element.children[0];
    if (img.clientWidth !== undefined && img.clientWidth > 0) {
      element.style.width = '' + img.clientWidth + 'px';
      element.style.height = '' + img.clientHeight + 'px';
    }
  }
}
